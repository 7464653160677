import ManCard from "./assets/images/card.png";
// import CheckIcon from "./assets/images/check-icon.png";
import Check from "./assets/images/check.png";
import Clock from "./assets/images/clock.png";
import Man from "./assets/images/man.png";
import Phone from "./assets/images/phone.png";

export const CARDS = [
  {
    time: "Q3 2024",
    title: <span className="text-white 3xl:text-[#00ED98]">BETA PHASE</span>,
    badge: (
      <div className="p-2 rounded-[48px] bg-white/20 items-center gap-2 w-fit text-white font-medium text-2xl flex mb-3">
        <img src={Check} alt="Check" />
        Complete
      </div>
    ),
    desc: (
      <>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-green-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white/90"></section>
          </section>
          Test beta version
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-green-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white/90"></section>
          </section>
          Release FunWithTon
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-green-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Reveal FunTap
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-green-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Build kickstarter campaigns
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Unlock FunTeams
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Unlock $NOT Withdrawal
        </div>
      </>
    ),
    img: (
      <img
        src={Man}
        alt="FunWithTon brand"
        className="hidden xl:block absolute bottom-0 right-9"
      />
    ),
  },
  {
    time: "Q4 2024",
    title: (
      <span className="text-white 3xl:text-[#00ED98]">RELEASE FUNWITHTON</span>
    ),
    badge: (
      <div className="p-2 rounded-[48px] bg-white/20 items-center gap-2 w-fit text-white font-medium text-2xl flex mb-3">
        <img src={Clock} alt="Clock icon" />
        Upcoming
      </div>
    ),
    desc: (
      <>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          FunTeam Campaigns
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Launch Marketing plan
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Reveal FunArt
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Partnership Campaigns
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          FunCoin exchange event
        </div>
      </>
    ),
    img: (
      <img
        src={Phone}
        alt="FunWithTon app"
        className="hidden xl:block absolute bottom-0 right-7 h-[352px]"
      />
    ),
  },
  {
    time: "Q1 2025",
    title: "FunArts",
    badge: (
      <div className="p-2 rounded-[48px] bg-white/20 items-center gap-2 w-fit text-white font-medium text-2xl flex mb-3">
        <img src={Clock} alt="Clock icon" />
        Upcoming
      </div>
    ),
    desc: (
      <>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Airdrop Announcement
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          Airdrop Eligibility criteria
        </div>
        <div className="flex gap-2 items-center whitespace-nowrap -ml-4">
          {/* <img src={CheckIcon} alt='Check Icon' /> */}
          <section className="w-3 h-3 rounded-full bg-gray-400 flex justify-center items-center">
            <section className="w-1.5 h-1.5 rounded-full bg-white"></section>
          </section>
          CEX Listings
        </div>
      </>
    ),
    img: (
      <img
        src={ManCard}
        alt="FunWithTon"
        className="hidden xl:block absolute bottom-0 right-[26px]"
      />
    ),
  },
];
