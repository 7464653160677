import React from "react";
import Man from "../assets/images/rounded-man.png";
import Badge1 from "../assets/images/badge1.png";
import Badge2 from "../assets/images/badge2.png";
import Badge3 from "../assets/images/badge3.png";
import Badge4 from "../assets/images/badge4.png";
import { Button } from "./ui/button";
const Banner = () => {
  return (
    <div className="relative pt-[49px] pb-[88px] md:px-8 px-4 object-cover flex flex-col items-center gap-3">
      <img
        src={Man}
        alt="Ton"
        className="md:w-[370px] md:h-[336px] w-[343px] h-[311px]"
      />
      <img
        src={Badge1}
        alt="FunWithTon Badge"
        className="xl:absolute top-[116px] 3xl:left-[428px] hidden xl:block xl:left-[188px]"
      />
      <img
        src={Badge2}
        alt="FunWithTon Badge"
        className="xl:absolute top-[116px] 3xl:right-[428px] hidden xl:block xl:right-[188px]"
      />
      <img
        src={Badge3}
        alt="FunWithTon Badge"
        className="xl:absolute top-[359px] 3xl:left-[310px] hidden xl:block xl:left-[70px]"
      />
      <img
        src={Badge4}
        alt="FunWithTon Badge"
        className="xl:absolute top-[359px] 3xl:right-[310px] hidden xl:block xl:right-[70px]"
      />
      <div className="flex flex-col items-center">
        <h1 className="text-center text-white font-bold lg:text-[64px] md:text-[50px] text-[40px] leading-[52px] md:leading-[83.2px] mb-6">
          Tap Your Way to Wealth
          <br className="hidden md:inline" /> with FunWithTon!
        </h1>
        <p className="font-medium text-[20px] lg:text-2xl text-center text-white/60 mb-8">
          FunWithTon is a revolutionary crypto game where your taps turn into
          <br className="hidden lg:inline" /> real earnings. Play, tap, and earn
          TON tokens effortlessly!
        </p>
        <Button
          //   url="https://t.me/FunWithTonBot"
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "https://t.me/FunWithTonBot";
            }
          }}
          className="bg-gradient h-fit outline-linear shadow-yellow text-white font-bold text-[28px] md:w-fit w-full md:text-[32px] py-6 px-[84.5px] rounded-[70px]"
        >
          Play Now!
        </Button>
      </div>
    </div>
  );
};

export default Banner;
