import "./App.css";
import Banner from "./components/Banner";
import CardsGroup from "./components/CardsGroup";
import Social from "./components/Social";

function App() {
  return (
    <div className="w-full h-full bg-main">
      <div className="pt-8 px-8">
        <Social />
      </div>
      <Banner />
      <CardsGroup />
      <div className="flex justify-center items-center pb-[52px]">
        <Social />
      </div>
    </div>
  );
}

export default App;
