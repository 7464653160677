import Twitter from "../assets/images/twitter.png";
import Telegram from "../assets/images/telegram.png";
import { Button } from "./ui/button";

const Social = () => {
  return (
    <div className="flex md:justify-start justify-center">
      <div className="p-[6px] pr-5 rounded-full border-2 border-white/10 w-fit flex gap-3 items-center">
        <div className="flex gap-[6px]">
          <Button className="p-[15px] w-[52px] h-[52px] rounded-[54px] bg-white/20">
            <a href="https://x.com/funwithton" rel="noreferrer" target="_blank">
              <img src={Twitter} alt="Twitter logo" className="w-6 h-[21px]" />
            </a>
          </Button>
          <Button className="p-[15px] w-[52px] h-[52px] rounded-[54px] bg-white/20">
            <a href="https://t.me/funwithton" rel="noreferrer" target="_blank">
              <img
                src={Telegram}
                className="w-[27px] h-[22.38px]"
                alt="Telegram logo"
              />
            </a>
          </Button>
        </div>
        <p className="font-medium text-[20px] text-white/60">Join Us!</p>
      </div>
    </div>
  );
};

export default Social;
