import { CARDS } from "../constant";
import Card from "./Card";

const CardsGroup = () => {
  return (
    <div className="pt-[45px] lg:pt-20 pb-[60px] px-4 md:px-8 xl:px-[151px] 3xl:px-[360px] ">
      <p className="mb-8 md:mb-[45px] text-center md:text-left text-white font-bold xl:text-[64px] md:text-[50px] text-[40px]">
        Roadmap
      </p>
      <div className="flex flex-col gap-6 lg:gap-10">
        {CARDS.map((card) => (
          <Card key={card.time} {...card} />
        ))}
      </div>
    </div>
  );
};

export default CardsGroup;
