import React, { ReactNode } from "react";
import { cn } from "../lib/utils";

const Card = ({
  time,
  title,
  className,
  desc,
  img,
  badge,
}: {
  time: string;
  title: string | ReactNode;
  className?: string;
  desc: ReactNode;
  img: ReactNode;
  badge?: ReactNode;
}) => {
  return (
    <div
      className={cn(
        "relative md:py-6 xl:py-12 md:px-10 p-6 overflow-hidden flex items-center xl:gap-0 gap-6 md:gap-10 md:flex-nowrap flex-wrap bg-bluedient border-[3px] border-solid border-[#76ACE4] rounded-[40px] shadow-blue",
        className
      )}
    >
      <p className="text-linear font-bold lg:text-[100px] md:text-[70px] text-[60px] whitespace-nowrap text-center 3xl:w-[520px] xl:w-[489px] w-auto">
        {time}
      </p>
      <div>
        {badge}
        <p className="font-bold text-[28px] leading-[42px] lg:text-3xl text-white mb-2">
          {title}
        </p>
        <section className="flex gap-x-2 h-full">
          <section className="min-h-16 w-1 rounded-full bg-white/50 flex"></section>
          <section className="font-medium text-[20px] md:text-2xl text-white/60 leading-[36px]">
            {desc}
          </section>
        </section>
      </div>
      {img}
    </div>
  );
};

export default Card;
